import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js'
import { useField, useFormikContext } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { ElementState } from '../../util/stripe-custom/index'
import { CardElementCtx } from './ShippingConfirm'

interface CardCheckoutType {
  errorMessage: string | undefined
  onFocus?: () => void
}

const CardCheckout: React.FC<CardCheckoutType> = ({ errorMessage, onFocus }) => {
  const { errors, touched, values } = useFormikContext<{
    cardNumberElement: ElementState
  }>()

  const [, { value: firstName }] = useField('firstName')
  const [cardNumberErrorMessage, setCardNumberErrorMessage] = useState('')
  const [cardExpiryErrorMessage, setCardExpiryErrorMessage] = useState('')
  const [cardCvcErrorMessage, setCardCvcErrorMessage] = useState('')

  const [cardNumberFieldStyle, setCardNumberFieldStyle] = useState('field-focus')
  const [cardExpiryFieldStyle, setCardExpiryFieldStyle] = useState('field-focus')
  const [cardCvcFieldStyle, setCardCvcFieldStyle] = useState('field-focus')

  const [expiryElementPlaceHolder, setExpiryElementPlaceHolder] = useState('Expiration (MM/YY)')

  let CardElementStore = useContext(CardElementCtx)

  const { cardError, setCardError }: any = CardElementStore

  useEffect(() => {
    const windowWidth = window.innerWidth
    if (windowWidth < 400) {
      setExpiryElementPlaceHolder('MM/YY')
    }
  }, [])

  useEffect(() => {
    if (cardNumberErrorMessage || cardExpiryErrorMessage || cardCvcErrorMessage) {
      setCardError('Card details')
    } else {
      setCardError('')
    }
  }, [cardNumberErrorMessage, cardExpiryErrorMessage, cardCvcErrorMessage])

  useEffect(() => {
    if (errorMessage) {
      handleCardNumber()
      handleCardExpiry()
      handleCardCvc()
    }
  }, [errorMessage])

  const style = {
    base: {
      color: '#004355',
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '16px',
      '::placeholder': {
        color: '#004355',
        fontFamily: `Montserrat, sans-serif`,
        fontSize: '16px'
      }
    },
    invalid: {
      color: '#ff220c',
      iconColor: '#ff220c'
    }
  }

  const inputStyleCardNumber = {
    showIcon: true,
    placeholder: 'Card Number',
    iconStyle: 'solid' as StripeCardNumberElementOptions['iconStyle'],
    hidePostalCode: true,
    style: style
  }
  const inputStyleCardExpiry = {
    placeholder: expiryElementPlaceHolder,
    style: style
  }
  const inputStyleCardCvc = {
    placeholder: 'CVC',
    style: style
  }

  const handlePhonePayment = () => {
    if (firstName && firstName.includes('PHONE PAYMENT')) {
      setCardNumberErrorMessage('')
      setCardExpiryErrorMessage('')
      setCardCvcErrorMessage('')
      return true
    }
    return false
  }

  const handleCardNumber = () => {
    const phonePayment = handlePhonePayment()
    // handle validation for card number field
    const cardNumberElement = document.getElementById('cardNumberElement')?.className
    if (!phonePayment && cardNumberElement) {
      if (/invalid|empty/i.test(cardNumberElement)) {
        setCardNumberErrorMessage('Please enter valid card number')
        setCardNumberFieldStyle('field-error')
        if (typeof window.gtag !== 'undefined') {
          window.gtag('event', 'card_number_error', {
            page: 'sign_up_checkout'
          })
        }
      } else if (cardNumberElement?.includes('complete')) {
        setCardNumberErrorMessage('')
        setCardNumberFieldStyle('field-success')
        if (typeof window.gtag !== 'undefined') {
          window.gtag('event', 'entered_card_number', {
            page: 'sign_up_checkout'
          })
        }
      }
    }
  }

  const handleCardExpiry = () => {
    const phonePayment = handlePhonePayment()
    // handle validation for card expiry field
    const cardExpiryElement = document.getElementById('cardExpiryElement')?.className
    if (!phonePayment && cardExpiryElement) {
      if (/invalid|empty/i.test(cardExpiryElement)) {
        setCardExpiryErrorMessage('Please enter valid expiry date')
        setCardExpiryFieldStyle('field-error')
        if (typeof window.gtag !== 'undefined') {
          window.gtag('event', 'card_date_error', {
            page: 'sign_up_checkout'
          })
        }
      } else if (cardExpiryElement?.includes('complete')) {
        setCardExpiryErrorMessage('')
        setCardExpiryFieldStyle('field-success')
        if (typeof window.gtag !== 'undefined') {
          window.gtag('event', 'entered_card_expiry', {
            page: 'sign_up_checkout'
          })
        }
      }
    }
  }

  const handleCardCvc = () => {
    const phonePayment = handlePhonePayment()
    // handle validation for card CVC field
    const cardCvcElement = document.getElementById('cardCvcElement')?.className
    if (!phonePayment && cardCvcElement) {
      if (/invalid|empty/i.test(cardCvcElement)) {
        setCardCvcErrorMessage('Please enter valid CVC')
        setCardCvcFieldStyle('field-error')
        if (typeof window.gtag !== 'undefined') {
          window.gtag('event', 'card_CVC_error', {
            page: 'sign_up_checkout'
          })
        }
      } else if (cardCvcElement?.includes('complete')) {
        setCardCvcErrorMessage('')
        setCardCvcFieldStyle('field-success')
        if (typeof window.gtag !== 'undefined') {
          window.gtag('event', 'entered_card_CVC', {
            page: 'sign_up_checkout'
          })
        }
      }
    }
  }

  const tabToCardExpiryField = (e: any) => {
    const stripeInputElement = document.getElementsByClassName('__PrivateStripeElement-input')
    if (e.complete) {
      // Tab to expiry date card field
      stripeInputElement[1]?.focus()
    }
  }

  const tabToCardCVCField = (e: any) => {
    const stripeInputElement = document.getElementsByClassName('__PrivateStripeElement-input')
    if (e.complete) {
      // Tab to CVC card field
      stripeInputElement[2]?.focus()
    }
  }

  return (
    <div>
      <div>
        <div className={`border-none bg-checkoutBG`}>
          <div
            className={`${cardNumberFieldStyle} rounded-md mb-2 flex w-full flex-col border border-gray-300 bg-white p-4 text-center focus:border-highlight focus:ring-highlight`}
          >
            <CardNumberElement
              id="cardNumberElement"
              options={inputStyleCardNumber}
              onBlur={handleCardNumber}
              onChange={(e: any) => tabToCardExpiryField(e)}
            />
          </div>
          {cardNumberErrorMessage && (
            <div className="card-validation my-2 text-sm text-red-500">{cardNumberErrorMessage}</div>
          )}
          <div className={`flex flex-row w-full`}>
            <div className="flex flex-col w-full">
              <div
                className={`${cardExpiryFieldStyle} rounded-md mr-1 border border-gray-300 bg-white p-4 text-center focus:border-highlight focus:ring-highlight`}
              >
                <CardExpiryElement
                  id="cardExpiryElement"
                  options={inputStyleCardExpiry}
                  onBlur={handleCardExpiry}
                  onChange={(e: any) => tabToCardCVCField(e)}
                />
              </div>
              {cardExpiryErrorMessage && (
                <div className="card-validation mt-2 text-sm text-red-500">{cardExpiryErrorMessage}</div>
              )}
            </div>
            <div className="flex flex-col w-full">
              <div
                className={`${cardCvcFieldStyle} rounded-md ml-1 border border-gray-300 bg-white p-4 text-center focus:border-highlight focus:ring-highlight`}
              >
                <CardCvcElement id="cardCvcElement" options={inputStyleCardCvc} onBlur={handleCardCvc} />
              </div>
              {cardCvcErrorMessage && (
                <div className="card-validation ml-2 mt-2 text-sm text-red-500">{cardCvcErrorMessage}</div>
              )}
            </div>
          </div>
        </div>
        {/*process.env.USER_BRANCH !== 'production' && (
          <div>
            <p>Test successful payment - 4242424242424242</p>
            <p>Generic Card declined - 4000000000000002</p>
            <p>Insufficient funds - 4000008260003178</p>
            <p>3DS - 4000002500003155</p>
            <p>Test cards - https://stripe.com/docs/testing</p>
          </div>
        )}*/}
        {/* {errorMessage && <div className="card-validation text-xs text-red-500">{errorMessage}</div>} */}
      </div>
    </div>
  )
}

export default CardCheckout
